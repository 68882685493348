.SegmentedControl {
  border: 1px solid;
  margin: 0 0 15px 0;
  display: flex;
  border-radius: 25px;
  color: #47cee4;
  overflow: hidden;
}

.SegmentedControl button {
  flex: 1;
  display: block;
  color: inherit;
  text-transform: uppercase;
  font-size: 11px;
  border-right: 1px solid;
  text-align: center;
  line-height: 24px;
}

.SegmentedControl button.selected {
  color: #FFF;
  background: #47cee4;
}

.SegmentedControl button:last-child {
  border-right: none;
}
