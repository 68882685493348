.PalettePicker {
  text-align: center;
  width: 600px;
  max-width: 100%;
}

.palette {
  display: inline-flex;
  justify-content: center;
  max-width: calc(100% - 90px);
}

.Picker {
  margin: 15px 0;
}

.Swatch {
  height: 30px;
  width: 30px;
  flex: 0 1 30px;
  display: inline-block;
  cursor: pointer;
  margin: 0 2px;
  transition: transform 200ms ease, box-shadow 200ms ease;
}

.Swatch:global.tippy-active {
  transform: scale(1.1);
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);
}

.addButton {
  height: 30px;
  width: 30px;
  flex: 0 0 30px;
  display: inline-block;
  border-radius: 50%;
  font-size: 26px;
  line-height: 30px;
  position: absolute;
  margin: 0 0 0 5px;
  transition: background 200ms ease;
  cursor: pointer;
  color: inherit;
}

.addButton:hover {
  background: hsl(0, 0%, 94%);
  color: inherit;
}

.removeButton {
  composes: addButton;
  margin: 0 0 0 35px;
}

:global .tippy-tooltip.colorpicker-theme {
  background: none;
  box-shadow: none;
  padding: 0 0;
}

.saveButton {
  display: inline-block;
  font-size: 11px;
  text-transform: uppercase;
  padding: 10px 20px;
  margin-top: 15px;
  border-radius: 25px;
  transition: background 200ms ease;
}

.saveButton:hover {
  background: rgba(71, 206, 228, 0.1);
}
