.CircleSpinner {
  display: inline-block;
  position: relative;
  height: 1.5em;
  width: 1.5em;
}

.CircleSpinner.inline {
  margin-bottom: -0.38em;
  margin-left: 0.2em;
}

.CircleSpinner svg {
  display: block;
  position: absolute;
  height: 1.5em;
  width: 1.5em;
  stroke: currentColor;
}

.group {
  animation: spin 800ms infinite cubic-bezier(0.595, 0.540, 0.315, 0.530);
  transform-origin: 50% 50%;
}
.circle {
  opacity: 0.3;
}
.spin {
  stroke-dasharray: 88;
  stroke-dashoffset: 66; /* 88 * 0.75 */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

