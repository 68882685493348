:global .ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  min-height: 100%;
}

.overlay {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal {
  min-width: 600px;
  background: #FFF;
  outline: none;
  border-radius: 2px;
  position: relative;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 30px;
  cursor: pointer;
  transition: transform 200ms ease;
}

@media screen and (max-width: 800px) {
  .Modal {
    width: 90%;
    min-width: 0;
  }
}
