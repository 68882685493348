.ModalWithPreview {
  width: 600px;
  max-width: 100%;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .ModalWithPreview {
    width: auto;
  }
}

.top {
  padding: 30px;
  background: hsl(0, 0%, 91%);
  flex: 1;
  align-items: center;
  justify-content: center;
}

.bottom {
  padding: 15px 30px 30px 30px;
}

.ModalWithPreview canvas {
  max-width: 100%;
  width: 300px;
  max-height: 100%;
  height: 200px;
  object-fit: contain;
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.2));
}
