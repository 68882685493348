.LayoutFixed {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.main {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.blur {
  filter: blur(6px);
}
