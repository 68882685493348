.Navbar {
  position: relative;
  flex: 0 0;
  z-index: 99;
  display: flex;
  align-items: center;
  /*background: linear-gradient(to right, #E0F2F1, #e2e2e2);*/
  background: linear-gradient(
      to left,
      #d53e4f,
      #fc8d59,
      #fee08b,
      #e6f598,
      #99d594,
      #3288bd
    ) left bottom repeat-x;
  background-size: 100% 1px;
  /*background-color: hsl(0, 0%, 85%);*/
  background-color: #FFF;
  justify-content: stretch;
  padding: 0 20px;
  height: 60px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
}

a.brand {
  margin: 0 30px 0 0;
}


.Navbar h1 {
  margin: 0 10px 0 0;
  display: inline-block;
  color: hsl(0, 0%, 20%);
  position: relative;
}

.left {
  /*border: 1px solid red;*/
  /*flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;*/
}

.center {
  flex: 1;
  /*border: 1px solid red;*/
  display: flex;
  justify-content: center;
}

.right {
  /*border: 1px solid red;*/
  /*flex: 1;
  display: flex;
  justify-content: flex-end;*/
}

.Navbar a, .Navbar a:visited {
  text-decoration: none;
  color: #616161;
  transition: color 200ms ease;
}

.logoLink {
  line-height: 0;
}

/* use pseudo-elements and letter-spacing to
   lessen the impact of changing font-weight */
a.NavLink {
  position: relative;
  letter-spacing: 0.48px;
  white-space: pre;
  margin: 0 15px;
}

a.NavLink.active {
  letter-spacing: 0px;
}

a.NavLink:before {
  display: inline-block;
  content: attr(data-text);
  font-weight: 600;
  letter-spacing: 0.48px;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

a.NavLink span {
  display: inline-block;
  position: absolute;
  left: 0;
}

.Navbar a:hover {
  color: #424242;
}

/* TODO - scope this to just the center links and prevent them from changing
width when active */
.Navbar a.active {
  font-weight: 600;
  color: #424242;
}

.center a {
  margin: 0 10px;
}

.profileImage {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: url(assets/img/profile.jpg) no-repeat center/cover;
  cursor: pointer;
  opacity: 0.9;
  transition: opacity 200ms ease;
}

.profileImage:hover {
  opacity: 1;
}

@media screen and (max-width: 899px) {
  .center {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .right {
    display: none;
  }
  a.brand {
    margin-right: auto;
  }
  .left a:last-child {
    margin-right: 0;
  }

  .Navbar {
    padding: 0 20px;
  }
}
