.Palette {
  height: 30px;
  display: flex;
  margin: 0 0 15px 0;
  box-shadow: 01px 1px 4px 1px rgba(0, 0, 0, 0.15);
  transition: transform 200ms ease;
}

.Palette.selected {
  border: 2px solid #47cee4;
  margin: -2px -2px 13px -2px;
  height: 34px;
}

.Palette:hover {
  transform: scale(1.02);
}

.swatch {
  flex: 1;
}

.add,
.random {
  align-items: center;
  justify-content: center;
  background:
    linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)),
    linear-gradient(to left, #77a1d3, #79cbca, #e684ae, #FD7536);
  font-size: 24px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
