
.exportButtons {
  /*display: flex;
  margin: 0 -15px 15px -15px;
  text-align: center;
  flex-wrap: wrap;*/
  text-align: left;
}

.button {
  /*flex: 1 1 40%;*/
  /*text-align: center;*/
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
  width: 100%;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 10px 15px;
  cursor: pointer;
  color: hsl(0, 0%, 50%);
  border: 1px solid hsl(0, 0%, 93%);
  transition: color 100ms ease, border 100ms ease;
  font-size: 13px;
}

.button:hover {
  color: #47cee4;
  border: 1px solid #47cee4;
}

.price {
  background: hsl(0, 0%, 70%);
  padding: 2px 6px;
  border-radius: 3px;
  display: inline-block;
  margin-left: 5px;
  margin-top: -2px;
  color: #FFF;
  margin-left: auto;
  transition: background 100ms ease, opacity 100ms ease;
}

.button:hover .price {
  background: #47cee4;
  opacity: 0.7;
}

.icon {
  font-size: 32px;
  margin-right: 15px;
}

@media screen and (max-width: 800px) {
  .button {
    margin: 5px 0;
    padding: 10px;
  }
  .ad {
    display: none;
  }
  .icon {
    margin-right: 5px;
  }
}
