/* 1.0666666667 = 16px icon ideal size divided by 15px body font size */
.icon {
  height: 1.2em;
  display: inline-block;
  position: relative;
  fill: currentColor;
  /* I'm using vertical-align top to fix the space added for descenders here
   * but this is likely going to cause issues in the future, and there should
   * be a better way to handle this
   */
  vertical-align: top;
}
