.FormControl label {
  margin: 0 0 2px 0;
}

.FormControl input {
  display: block;
  width: 100%;
  margin: 0 0 15px 0;
  font-size: 1rem;
  padding: 10px 15px;
  border: 1px solid hsl(0, 0%, 90%); /* $color-border-normal */
  border-radius: 20px;
  color: inherit;
  transition: border 200ms ease;
  -webkit-appearance: none;
}

.FormControl input::placeholder {
  color: hsl(0, 0%, 60%);
}

.FormControl input,
.FormControl input:focus,
.FormControl input:active {
  outline: none;
}

.FormControl input:focus, .FormControl input:hover {
  color: #424242;
  border-color: hsl(0, 0%, 80%); /* $color-border-focus */
}


/* range slider stuff */
input[type=range],
input[type=range]:hover,
input[type=range]:focus {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    margin: 10px 0 25px 0;
    padding: 0 0;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: #ffc785;
    background:
      linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
      linear-gradient(to right, #40e0d0, #ff8c00, #ff0080) -5px center/110%;
}

input[type=range]::-moz-range-track {
    background-color: transparent
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #FFF;
    opacity: 1;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    margin-top: -1px;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
}

input[type=range]::-moz-range-thumb {
    background: #FFF;
    opacity: 1;
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    box-sizing: border-box;
    margin-top: -1px;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
}

input[type=range]::-moz-focus-outer {
    border: 0
}
