.DownloadView {
  margin-top: 50px;
  text-align: center;
}

.DownloadView h1 {
  margin-bottom: 20px;
}

/* TODO - upload a proper small preview to S3 */
.preview {
  margin-bottom: 40px;
  border-radius: 15px;
  /* previews are capped to 400px, this gives us retina resolution */
  max-width: 200px;
  max-height: 200px;
}

a.dlButton {
  display: inline-flex;
  border: 1px solid;
  max-width: 400px;
  margin: 0 auto;
  width: 350px;
  max-width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-weight: 500;
}
