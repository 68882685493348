.LayoutFixed_LayoutFixed__3WazP {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.LayoutFixed_main__Mm4RH {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.LayoutFixed_blur__2oVKb {
  filter: blur(6px);
}

.Navbar_Navbar__1Oek3 {
  position: relative;
  flex: 0 0;
  z-index: 99;
  display: flex;
  align-items: center;
  /*background: linear-gradient(to right, #E0F2F1, #e2e2e2);*/
  background: linear-gradient(
      to left,
      #d53e4f,
      #fc8d59,
      #fee08b,
      #e6f598,
      #99d594,
      #3288bd
    ) left bottom repeat-x;
  background-size: 100% 1px;
  /*background-color: hsl(0, 0%, 85%);*/
  background-color: #FFF;
  justify-content: stretch;
  padding: 0 20px;
  height: 60px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
}

a.Navbar_brand__1nibi {
  margin: 0 30px 0 0;
}


.Navbar_Navbar__1Oek3 h1 {
  margin: 0 10px 0 0;
  display: inline-block;
  color: hsl(0, 0%, 20%);
  position: relative;
}

.Navbar_left__2-bUv {
  /*border: 1px solid red;*/
  /*flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;*/
}

.Navbar_center__CMj_G {
  flex: 1 1;
  /*border: 1px solid red;*/
  display: flex;
  justify-content: center;
}

.Navbar_right__3o5un {
  /*border: 1px solid red;*/
  /*flex: 1;
  display: flex;
  justify-content: flex-end;*/
}

.Navbar_Navbar__1Oek3 a, .Navbar_Navbar__1Oek3 a:visited {
  text-decoration: none;
  color: #616161;
  transition: color 200ms ease;
}

.Navbar_logoLink__3B37H {
  line-height: 0;
}

/* use pseudo-elements and letter-spacing to
   lessen the impact of changing font-weight */
a.Navbar_NavLink__1Nsaz {
  position: relative;
  letter-spacing: 0.48px;
  white-space: pre;
  margin: 0 15px;
}

a.Navbar_NavLink__1Nsaz.Navbar_active__1oQS6 {
  letter-spacing: 0px;
}

a.Navbar_NavLink__1Nsaz:before {
  display: inline-block;
  content: attr(data-text);
  font-weight: 600;
  letter-spacing: 0.48px;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

a.Navbar_NavLink__1Nsaz span {
  display: inline-block;
  position: absolute;
  left: 0;
}

.Navbar_Navbar__1Oek3 a:hover {
  color: #424242;
}

/* TODO - scope this to just the center links and prevent them from changing
width when active */
.Navbar_Navbar__1Oek3 a.Navbar_active__1oQS6 {
  font-weight: 600;
  color: #424242;
}

.Navbar_center__CMj_G a {
  margin: 0 10px;
}

.Navbar_profileImage__1qH40 {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: url(/static/media/profile.2b50a6b8.jpg) no-repeat center/cover;
  cursor: pointer;
  opacity: 0.9;
  transition: opacity 200ms ease;
}

.Navbar_profileImage__1qH40:hover {
  opacity: 1;
}

@media screen and (max-width: 899px) {
  .Navbar_center__CMj_G {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .Navbar_right__3o5un {
    display: none;
  }
  a.Navbar_brand__1nibi {
    margin-right: auto;
  }
  .Navbar_left__2-bUv a:last-child {
    margin-right: 0;
  }

  .Navbar_Navbar__1Oek3 {
    padding: 0 20px;
  }
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  min-height: 100%;
}

.Modal_overlay__2lmdD {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal_Modal__rWP0v {
  min-width: 600px;
  background: #FFF;
  outline: none;
  border-radius: 2px;
  position: relative;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.Modal_close__1c-Gd {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 30px;
  cursor: pointer;
  transition: transform 200ms ease;
}

@media screen and (max-width: 800px) {
  .Modal_Modal__rWP0v {
    width: 90%;
    min-width: 0;
  }
}

/* 1.0666666667 = 16px icon ideal size divided by 15px body font size */
.icons_icon__37Bm- {
  height: 1.2em;
  display: inline-block;
  position: relative;
  fill: currentColor;
  /* I'm using vertical-align top to fix the space added for descenders here
   * but this is likely going to cause issues in the future, and there should
   * be a better way to handle this
   */
  vertical-align: top;
}

.ModalWithPreview_ModalWithPreview__2a_OQ {
  width: 600px;
  max-width: 100%;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .ModalWithPreview_ModalWithPreview__2a_OQ {
    width: auto;
  }
}

.ModalWithPreview_top__22l49 {
  padding: 30px;
  background: hsl(0, 0%, 91%);
  flex: 1 1;
  align-items: center;
  justify-content: center;
}

.ModalWithPreview_bottom__KbrW1 {
  padding: 15px 30px 30px 30px;
}

.ModalWithPreview_ModalWithPreview__2a_OQ canvas {
  max-width: 100%;
  width: 300px;
  max-height: 100%;
  height: 200px;
  object-fit: contain;
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.2));
}

.CircleSpinner_CircleSpinner__2R28K {
  display: inline-block;
  position: relative;
  height: 1.5em;
  width: 1.5em;
}

.CircleSpinner_CircleSpinner__2R28K.CircleSpinner_inline__3FJzj {
  margin-bottom: -0.38em;
  margin-left: 0.2em;
}

.CircleSpinner_CircleSpinner__2R28K svg {
  display: block;
  position: absolute;
  height: 1.5em;
  width: 1.5em;
  stroke: currentColor;
}

.CircleSpinner_group__ZFDH7 {
  animation: CircleSpinner_spin__3uB4H 800ms infinite cubic-bezier(0.595, 0.540, 0.315, 0.530);
  transform-origin: 50% 50%;
}
.CircleSpinner_circle__1IiYL {
  opacity: 0.3;
}
.CircleSpinner_spin__3uB4H {
  stroke-dasharray: 88;
  stroke-dashoffset: 66; /* 88 * 0.75 */
}

@keyframes CircleSpinner_spin__3uB4H {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



.ExportDialog_exportButtons__31jJA {
  /*display: flex;
  margin: 0 -15px 15px -15px;
  text-align: center;
  flex-wrap: wrap;*/
  text-align: left;
}

.ExportDialog_button__oOvpR {
  /*flex: 1 1 40%;*/
  /*text-align: center;*/
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
  width: 100%;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 10px 15px;
  cursor: pointer;
  color: hsl(0, 0%, 50%);
  border: 1px solid hsl(0, 0%, 93%);
  transition: color 100ms ease, border 100ms ease;
  font-size: 13px;
}

.ExportDialog_button__oOvpR:hover {
  color: #47cee4;
  border: 1px solid #47cee4;
}

.ExportDialog_price__1_Smn {
  background: hsl(0, 0%, 70%);
  padding: 2px 6px;
  border-radius: 3px;
  display: inline-block;
  margin-left: 5px;
  margin-top: -2px;
  color: #FFF;
  margin-left: auto;
  transition: background 100ms ease, opacity 100ms ease;
}

.ExportDialog_button__oOvpR:hover .ExportDialog_price__1_Smn {
  background: #47cee4;
  opacity: 0.7;
}

.ExportDialog_icon__11WbL {
  font-size: 32px;
  margin-right: 15px;
}

@media screen and (max-width: 800px) {
  .ExportDialog_button__oOvpR {
    margin: 5px 0;
    padding: 10px;
  }
  .ExportDialog_ad__1BvWT {
    display: none;
  }
  .ExportDialog_icon__11WbL {
    margin-right: 5px;
  }
}

.PalettePicker_PalettePicker__rBetD {
  text-align: center;
  width: 600px;
  max-width: 100%;
}

.PalettePicker_palette__1ivoZ {
  display: inline-flex;
  justify-content: center;
  max-width: calc(100% - 90px);
}

.PalettePicker_Picker__1ADr5 {
  margin: 15px 0;
}

.PalettePicker_Swatch__1e96L {
  height: 30px;
  width: 30px;
  flex: 0 1 30px;
  display: inline-block;
  cursor: pointer;
  margin: 0 2px;
  transition: transform 200ms ease, box-shadow 200ms ease;
}

.PalettePicker_Swatch__1e96L.tippy-active {
  transform: scale(1.1);
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);
}

.PalettePicker_addButton__1DV-y {
  height: 30px;
  width: 30px;
  flex: 0 0 30px;
  display: inline-block;
  border-radius: 50%;
  font-size: 26px;
  line-height: 30px;
  position: absolute;
  margin: 0 0 0 5px;
  transition: background 200ms ease;
  cursor: pointer;
  color: inherit;
}

.PalettePicker_addButton__1DV-y:hover {
  background: hsl(0, 0%, 94%);
  color: inherit;
}

.PalettePicker_removeButton__2aygw {
  margin: 0 0 0 35px;
}

.tippy-tooltip.colorpicker-theme {
  background: none;
  box-shadow: none;
  padding: 0 0;
}

.PalettePicker_saveButton__A5m7y {
  display: inline-block;
  font-size: 11px;
  text-transform: uppercase;
  padding: 10px 20px;
  margin-top: 15px;
  border-radius: 25px;
  transition: background 200ms ease;
}

.PalettePicker_saveButton__A5m7y:hover {
  background: rgba(71, 206, 228, 0.1);
}

.FormControl_FormControl__2zw2M label {
  margin: 0 0 2px 0;
}

.FormControl_FormControl__2zw2M input {
  display: block;
  width: 100%;
  margin: 0 0 15px 0;
  font-size: 1rem;
  padding: 10px 15px;
  border: 1px solid hsl(0, 0%, 90%); /* $color-border-normal */
  border-radius: 20px;
  color: inherit;
  transition: border 200ms ease;
  -webkit-appearance: none;
}

.FormControl_FormControl__2zw2M input::placeholder {
  color: hsl(0, 0%, 60%);
}

.FormControl_FormControl__2zw2M input,
.FormControl_FormControl__2zw2M input:focus,
.FormControl_FormControl__2zw2M input:active {
  outline: none;
}

.FormControl_FormControl__2zw2M input:focus, .FormControl_FormControl__2zw2M input:hover {
  color: #424242;
  border-color: hsl(0, 0%, 80%); /* $color-border-focus */
}


/* range slider stuff */
input[type=range],
input[type=range]:hover,
input[type=range]:focus {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    margin: 10px 0 25px 0;
    padding: 0 0;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: #ffc785;
    background:
      linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
      linear-gradient(to right, #40e0d0, #ff8c00, #ff0080) -5px center/110%;
}

input[type=range]::-moz-range-track {
    background-color: transparent
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #FFF;
    opacity: 1;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    margin-top: -1px;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
}

input[type=range]::-moz-range-thumb {
    background: #FFF;
    opacity: 1;
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    box-sizing: border-box;
    margin-top: -1px;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
}

input[type=range]::-moz-focus-outer {
    border: 0
}

.Palette_Palette__3AJR8 {
  height: 30px;
  display: flex;
  margin: 0 0 15px 0;
  box-shadow: 01px 1px 4px 1px rgba(0, 0, 0, 0.15);
  transition: transform 200ms ease;
}

.Palette_Palette__3AJR8.Palette_selected__sBYNN {
  border: 2px solid #47cee4;
  margin: -2px -2px 13px -2px;
  height: 34px;
}

.Palette_Palette__3AJR8:hover {
  transform: scale(1.02);
}

.Palette_swatch__FTL5X {
  flex: 1 1;
}

.Palette_add__2PWke,
.Palette_random__3jaxC {
  align-items: center;
  justify-content: center;
  background:
    linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)),
    linear-gradient(to left, #77a1d3, #79cbca, #e684ae, #FD7536);
  font-size: 24px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.SegmentedControl_SegmentedControl__2pNlu {
  border: 1px solid;
  margin: 0 0 15px 0;
  display: flex;
  border-radius: 25px;
  color: #47cee4;
  overflow: hidden;
}

.SegmentedControl_SegmentedControl__2pNlu button {
  flex: 1 1;
  display: block;
  color: inherit;
  text-transform: uppercase;
  font-size: 11px;
  border-right: 1px solid;
  text-align: center;
  line-height: 24px;
}

.SegmentedControl_SegmentedControl__2pNlu button.SegmentedControl_selected__2VGxh {
  color: #FFF;
  background: #47cee4;
}

.SegmentedControl_SegmentedControl__2pNlu button:last-child {
  border-right: none;
}

.PaletteList_PaletteList__NqZqp {

}

.Sidebar_Sidebar__1MJxB {
  background: #FFF;
  max-height: 100%;
  width: 100%;
  padding: 20px;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.Sidebar_row__1k8qe {
  display: flex;
  margin: 0 -10px;
}

.Sidebar_col__29_iV {
  flex: 1 1;
  margin: 0 10px;
}

.Sidebar_randomize__1iR-F {
  display: block;
  width: 100%;
  margin: 0 0 15px 0;
  font-size: 11px;
  padding: 10px 30px;
  text-transform: uppercase;
  border-radius: 20px;
  background: #FFF;
  transition:
    transform 200ms ease,
    box-shadow 200ms ease,
    background 100ms ease;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.18);
  transform: scale(1.0001);
}

.Sidebar_randomize__1iR-F:hover {
  transform: scale(1.02);
  box-shadow: 1px 2px 6px 2px rgba(0, 0, 0, 0.1);
}

.Sidebar_randomize__1iR-F:active {
  transform: scale(1.01);
}

.Preview_Preview__3Jl3I {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: hsl(0, 0%, 91%);
  height: 100%;
  padding: 40px 60px 70px 60px;
}

.Preview_Preview__3Jl3I canvas {
  max-height: 100%;
  max-width: 100%;
  flex: 1 1 auto;
  height: auto;
  width: auto;
  object-fit: contain;
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.2));
}

.Preview_actions__36lSK {
  display: block;
  left: 0;
  right: 0;
  bottom: 25px;
  text-align: center;
  position: absolute;
  /*background: rebeccapurple;*/
}

.Preview_ActionButton__AOO_a {
  display: inline-block;
  vertical-align: middle;
  height: 35px;
  font-size: 13px;
  text-transform: uppercase;
  margin-top: -3px;
  padding: 0 15px;
  transition: transform 200ms ease, box-shadow 200ms ease;
  background: #FFF;
  border-radius:  30px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
  transform: scale(1.0001);
}

.Preview_ActionButton__AOO_a svg {
  font-size: 18px;
  display: inline-block;
  margin-top: -4px;
}

.Preview_ActionButton__AOO_a:hover {
  /*color: #FFF;*/
  transform: scale(1.03);
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1)
}

@media screen and (max-width: 800px) {
  .Preview_Preview__3Jl3I {
    padding: 20px 20px 40px 20px;
  }
}

.NewPatternView_NewPatternView__2a7QV {
  display: flex;
  height: 100%;
  position: relative;
}

.NewPatternView_sidebar__2fhEb {
  width: 400px;
  flex: 0 0 400px;
  z-index: 2;
  position: relative;
  box-shadow: 2px 0px 8px 0 rgba(0, 0, 0, 0.15);
}

.NewPatternView_preview__2qc3i {
  flex: 1 1 auto;
  z-index: 1;
  position: relative;
}

@media screen and (max-width: 800px) {
  .NewPatternView_NewPatternView__2a7QV {
    flex-direction: column-reverse;
  }
  .NewPatternView_preview__2qc3i {
    height: 40vh;
    flex: 0 0 40vh;
  }
  .NewPatternView_sidebar__2fhEb {
    min-height: 0;
    flex: 1 1;
    width: 100%;
  }
}

.PatternsView_PatternsView__2jC3a {

}

.AboutView_AboutView__28kEY {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  padding: 0 30px;
}

.AboutView_content__XR-V0 {
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 200px;
}

.AboutView_AboutView__28kEY h1 {
  margin-top: 60px;
}

.DownloadView_DownloadView__2f7Jy {
  margin-top: 50px;
  text-align: center;
}

.DownloadView_DownloadView__2f7Jy h1 {
  margin-bottom: 20px;
}

/* TODO - upload a proper small preview to S3 */
.DownloadView_preview__1AXuk {
  margin-bottom: 40px;
  border-radius: 15px;
  /* previews are capped to 400px, this gives us retina resolution */
  max-width: 200px;
  max-height: 200px;
}

a.DownloadView_dlButton__1Lz44 {
  display: inline-flex;
  border: 1px solid;
  max-width: 400px;
  margin: 0 auto;
  width: 350px;
  max-width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-weight: 500;
}

html {
  box-sizing: border-box;
  /* position:fixed prevents body bounce but causes input bugs */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.6em;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",  /* Emojis*/
    "Segoe UI Emoji", /* Emojis*/
    "Segoe UI Symbol"; /* Emojis*/
  font-size: 14px;
  line-height: 1.6em; /* 18px / 15px = 1.2 */
  /* needed to prevent overscrolling bounce */
  height: 100%;
  overflow: hidden;
  color: hsl(0, 0%, 20%);
}


/***** TYPOGRAPHY *****/
h1 {
  font-family: 'proxima-soft', "Proxima Nova", "Open Sans", "Gill Sans MT", "Gill Sans", Corbel, Arial, sans-serif;
  font-weight: 400;
  margin: 0 0 30px 0;
}

h2 {
  font-family: 'proxima-nova', "Proxima Nova", "Open Sans", "Gill Sans MT", "Gill Sans", Corbel, Arial, sans-serif;
  font-weight: 500;
  font-size: 24px;
  margin: 0 0 15px 0;
}

p {
  margin: 0 0 15px 0;
}

a {
  color: #47cee4; /* $color-brand-primary */
  cursor: pointer;
  text-decoration: none;
  transition: color 150ms ease;
}

a:hover, a:visited {
  color: #47cee4;
}

label {
  display: block;
  font-weight: 400;
  font-size: 11px;
  text-transform: uppercase;
  color: hsl(0, 0%, 40%);
}

button {
  margin: 0 0;
  padding: 0 0;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: inherit;
  background: none;
  color: #47cee4;
  display: block;
}

/* inputs */
input[type=number] {
  -moz-appearance: textfield;
}

/* Remove controls from Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Removes leftover margin */
}


body.tippy-touch .tippy-popper {
  display: none;
}

body.tippy-touch .tippy-popper.colorpicker,
body.tippy-touch .tippy-popper.manual {
  display: block;
}

