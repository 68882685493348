.Sidebar {
  background: #FFF;
  max-height: 100%;
  width: 100%;
  padding: 20px;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.row {
  display: flex;
  margin: 0 -10px;
}

.col {
  flex: 1 1 0px;
  margin: 0 10px;
}

.randomize {
  display: block;
  width: 100%;
  margin: 0 0 15px 0;
  font-size: 11px;
  padding: 10px 30px;
  text-transform: uppercase;
  border-radius: 20px;
  background: #FFF;
  transition:
    transform 200ms ease,
    box-shadow 200ms ease,
    background 100ms ease;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.18);
  transform: scale(1.0001);
}

.randomize:hover {
  transform: scale(1.02);
  box-shadow: 1px 2px 6px 2px rgba(0, 0, 0, 0.1);
}

.randomize:active {
  transform: scale(1.01);
}
