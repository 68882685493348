.Preview {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: hsl(0, 0%, 91%);
  height: 100%;
  padding: 40px 60px 70px 60px;
}

.Preview canvas {
  max-height: 100%;
  max-width: 100%;
  flex: 1 1 auto;
  height: auto;
  width: auto;
  object-fit: contain;
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.2));
}

.actions {
  display: block;
  left: 0;
  right: 0;
  bottom: 25px;
  text-align: center;
  position: absolute;
  /*background: rebeccapurple;*/
}

.ActionButton {
  display: inline-block;
  vertical-align: middle;
  height: 35px;
  font-size: 13px;
  text-transform: uppercase;
  margin-top: -3px;
  padding: 0 15px;
  transition: transform 200ms ease, box-shadow 200ms ease;
  background: #FFF;
  border-radius:  30px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
  transform: scale(1.0001);
}

.ActionButton svg {
  font-size: 18px;
  display: inline-block;
  margin-top: -4px;
}

.ActionButton:hover {
  /*color: #FFF;*/
  transform: scale(1.03);
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1)
}

@media screen and (max-width: 800px) {
  .Preview {
    padding: 20px 20px 40px 20px;
  }
}
