.NewPatternView {
  display: flex;
  height: 100%;
  position: relative;
}

.sidebar {
  width: 400px;
  flex: 0 0 400px;
  z-index: 2;
  position: relative;
  box-shadow: 2px 0px 8px 0 rgba(0, 0, 0, 0.15);
}

.preview {
  flex: 1 1 auto;
  z-index: 1;
  position: relative;
}

@media screen and (max-width: 800px) {
  .NewPatternView {
    flex-direction: column-reverse;
  }
  .preview {
    height: 40vh;
    flex: 0 0 40vh;
  }
  .sidebar {
    min-height: 0;
    flex: 1 1 0px;
    width: 100%;
  }
}
