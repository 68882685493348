.AboutView {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  padding: 0 30px;
}

.content {
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 200px;
}

.AboutView h1 {
  margin-top: 60px;
}
