html {
  box-sizing: border-box;
  /* position:fixed prevents body bounce but causes input bugs */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.6em;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",  /* Emojis*/
    "Segoe UI Emoji", /* Emojis*/
    "Segoe UI Symbol"; /* Emojis*/
  font-size: 14px;
  line-height: 1.6em; /* 18px / 15px = 1.2 */
  /* needed to prevent overscrolling bounce */
  height: 100%;
  overflow: hidden;
  color: hsl(0, 0%, 20%);
}


/***** TYPOGRAPHY *****/
h1 {
  font-family: 'proxima-soft', "Proxima Nova", "Open Sans", "Gill Sans MT", "Gill Sans", Corbel, Arial, sans-serif;
  font-weight: 400;
  margin: 0 0 30px 0;
}

h2 {
  font-family: 'proxima-nova', "Proxima Nova", "Open Sans", "Gill Sans MT", "Gill Sans", Corbel, Arial, sans-serif;
  font-weight: 500;
  font-size: 24px;
  margin: 0 0 15px 0;
}

p {
  margin: 0 0 15px 0;
}

a {
  color: #47cee4; /* $color-brand-primary */
  cursor: pointer;
  text-decoration: none;
  transition: color 150ms ease;
}

a:hover, a:visited {
  color: #47cee4;
}

label {
  display: block;
  font-weight: 400;
  font-size: 11px;
  text-transform: uppercase;
  color: hsl(0, 0%, 40%);
}

button {
  margin: 0 0;
  padding: 0 0;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: inherit;
  background: none;
  color: #47cee4;
  display: block;
}

/* inputs */
input[type=number] {
  -moz-appearance: textfield;
}

/* Remove controls from Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Removes leftover margin */
}


:global body.tippy-touch .tippy-popper {
  display: none;
}

:global body.tippy-touch .tippy-popper.colorpicker,
:global body.tippy-touch .tippy-popper.manual {
  display: block;
}
